import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React from "react";
import { NO_BUILDING_IMAGE_PATH } from "../../../../config";
import { GraphQLBuildingImageType } from "../../../../graphql";
import { grid, lineClamp } from "../../../../styles/mixin";
import { Color, Spacing } from "../../../../tokens";
import { formatBuildingFloors } from "../../../../utils/BuildingFormatter";
import { selectLatestBuildingImage } from "../../../../utils/BuildingImageSelector";
import Avatar from "../../../uiParts/dataDisplay/Avatar";
import {
  Row as TableRow,
  Cell as TableCell,
} from "../../../uiParts/dataDisplay/Table";
import MoreMenu from "../../../uiParts/navigation/MoreMenu";
import TextLink from "../../../uiParts/navigation/TextLink";

type BuildingOldName = {
  name: string;
  renamedYear: number;
  renamedMonth: number;
};

type BuildingItemProps = {
  id: number;
  name: string;
  oldNames: BuildingOldName[];
  address: string;
  floors: string[];
  pmLessorUser?: {
    name: string;
    imageUrl?: string | null | undefined;
  } | null;
  lmLessorUser?: {
    name: string;
    imageUrl?: string | null | undefined;
  } | null;
  buildingImages: {
    id: number;
    url: string;
    imageType: GraphQLBuildingImageType;
  }[];
};

type Props = {
  building: BuildingItemProps;
  color?: React.CSSProperties["color"];
  onClickEdit: () => void;
};

const BuildingItem: React.FC<Props> = ({ building, color, onClickEdit }) => {
  const imageUrl = selectLatestBuildingImage(
    building.buildingImages,
    GraphQLBuildingImageType.Exterior,
  );
  const { pmLessorUser, lmLessorUser } = building;

  return (
    <TableRow css={rowStyle(color)} testId="building_list_table_row" isNoBorder>
      <TableCell
        horizontalAlign="Left"
        verticalAlign="Center"
        textAlign="Left"
        width="100%"
      >
        <div css={buildingStyle}>
          <BuildingImageViewer src={imageUrl} />
          <div css={infoStyle}>
            <div>
              <TextLink
                href={`/lessor/properties/${building.id}`}
                isBold
                css={nameStyle}
              >
                {building.name}
                {building.oldNames[0] && (
                  <span
                    css={oldNameStyle}
                  >{`(旧${building.oldNames[0]?.name})`}</span>
                )}
              </TextLink>
            </div>
            <div css={addressStyle}>{building.address}</div>
          </div>
        </div>
      </TableCell>
      <TableCell
        horizontalAlign="Left"
        verticalAlign="Center"
        textAlign="Left"
        width="100%"
      >
        <div css={floorsStyle}>{formatBuildingFloors(building.floors)}</div>
      </TableCell>
      <TableCell
        horizontalAlign="Center"
        verticalAlign="Center"
        textAlign="Center"
        maxWidth="180px"
        minWidth="180px"
      >
        <Avatar
          isUnassigned={!pmLessorUser}
          name={pmLessorUser?.name}
          imgSrc={pmLessorUser?.imageUrl ?? undefined}
        />
      </TableCell>
      <TableCell
        horizontalAlign="Center"
        verticalAlign="Center"
        textAlign="Center"
        maxWidth="180px"
        minWidth="180px"
      >
        <Avatar
          isUnassigned={!lmLessorUser}
          name={lmLessorUser?.name}
          imgSrc={lmLessorUser?.imageUrl ?? undefined}
        />
      </TableCell>
      <TableCell
        horizontalAlign="Center"
        verticalAlign="Center"
        textAlign="Center"
        maxWidth="48px"
        minWidth="48px"
      >
        <MoreMenu
          size={32}
          menuList={{
            width: "80px",
            menuItems: [
              {
                label: "編集",
                onClick: onClickEdit,
              },
            ],
          }}
          icon="Ellipsis"
        />
      </TableCell>
    </TableRow>
  );
};

const BuildingImageViewer = ({ src, alt }: { src?: string; alt?: string }) => {
  return <img src={src || NO_BUILDING_IMAGE_PATH} alt={alt} css={imgStyle} />;
};

const rowStyle = (color?: React.CSSProperties["color"]) => css`
  background: ${color ? color : Color.White};
`;

const buildingStyle = css`
  width: 100%;
  ${grid("40px 1fr", Spacing[8], "none", "normal")}
  align-items: center;
`;

const imgStyle = css`
  width: 40px;
  height: 40px;
  object-fit: contain;
  background: ${Color.White};
  border: solid 1px ${Color.Neutral.Light.Primary};
`;

const infoStyle = css`
  width: 100%;
  overflow: hidden;
  ${lineClamp(1)};
`;

const nameStyle = css`
  font-size: ${text.size[14]};
  ${lineClamp(1)}
`;

const oldNameStyle = css`
  font-size: ${text.size[12]};
  margin-left: ${Spacing[4]};
`;

const floorsStyle = css`
  width: 100%;
  font-size: ${text.size[12]};
  ${lineClamp(1)}
`;

const addressStyle = css`
  font-size: ${text.size[12]};
  color: ${Color.Neutral.Base.Secondary};
`;

export default BuildingItem;
