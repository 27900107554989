import { useCallback, useMemo, useState } from "react";
import { GraphQLLessorBranch } from "../../../../../../../graphql";

export const useBranchesFilter = (
  lessorBranches: GraphQLLessorBranch[],
  lessorBranchId: number | undefined,
) => {
  // 単純なフロントでの支店絞り込み。
  const [selectedBranches, setSelectedBranch] = useState<number[]>(
    lessorBranchId ? [lessorBranchId] : [],
  );

  const onSelectedBranchesChange = useCallback((branches: number[]) => {
    setSelectedBranch(branches);
  }, []);

  const lessorBranchItems = useMemo(
    () =>
      lessorBranches.map((branch) => ({
        label: branch.name,
        value: branch.id,
      })),
    [lessorBranches],
  );

  return {
    selectedBranches,
    onSelectedBranchesChange,
    lessorBranchItems,
  };
};
