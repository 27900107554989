import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { faSearch, faSortDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { escapeRegExp } from "lodash";
import React, {
  ChangeEvent,
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Color, Spacing } from "../../../../tokens";
import Dropdown from "../../dataDisplay/Dropdown";
import Checkbox from "../Checkbox";
import Inputbox from "../Inputbox";

type Props<T extends string | number> = {
  selectedValues: T[];
  items: {
    value: T;
    label: string;
  }[];
  verticalPosition?: ComponentProps<typeof Dropdown>["verticalPosition"];
  horizontalPosition?: ComponentProps<typeof Dropdown>["horizontalPosition"];
  onChange?: (selectedValuesState: T[]) => void;
} & (
  | {
      label: string;
      placeholder?: string;
    }
  | {
      label?: string;
      placeholder: string;
    }
  | {
      label: undefined;
      placeholder: never;
    }
  | {
      label: never;
      placeholder: undefined;
    }
) &
  (
    | {
        hasSearchBox?: false;
        searchBoxPlaceholder?: never;
      }
    | {
        hasSearchBox: true;
        searchBoxPlaceholder?: string;
      }
  );

const DropdownCheckboxes = <T extends string | number>({
  selectedValues = [],
  label,
  placeholder,
  items,
  hasSearchBox = false,
  searchBoxPlaceholder = "入力してください",
  verticalPosition,
  horizontalPosition,
  onChange,
}: Props<T>): ReturnType<React.FC> => {
  const [selectedValuesState, setSelectedValuesState] =
    useState<T[]>(selectedValues);
  const [query, setQuery] = useState<string>("");

  const [isFirstRender, setIsFirstRender] = useState(true);
  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const isSelected = useMemo(() => {
    return selectedValuesState.length > 0;
  }, [selectedValuesState]);

  const checkboxes = useMemo(
    () =>
      items.map(({ value, label: itemLabel }) => {
        const isChecked = selectedValuesState.includes(value);
        const onCheckboxChange = () => {
          setSelectedValuesState((prev) =>
            isChecked
              ? prev.filter((prevValue) => prevValue !== value)
              : [...prev, value],
          );
        };

        return {
          isChecked,
          onChange: onCheckboxChange,
          value: value.toString(),
          children: itemLabel,
        };
      }),
    [items, selectedValuesState],
  );

  const selectedLabels = useMemo(() => {
    return checkboxes
      .filter(({ isChecked }) => isChecked)
      .map(({ children }) => children);
  }, [checkboxes]);

  const filteredCheckboxes = useMemo(() => {
    return checkboxes.filter((checkbox) => {
      return !!checkbox.children.toLocaleLowerCase().match(escapeRegExp(query));
    });
  }, [checkboxes, query]);

  const onInputboxChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    },
    [],
  );

  const onAllClearClick = useCallback(() => {
    setSelectedValuesState([]);
  }, []);

  useEffect(() => {
    setSelectedValuesState(selectedValues);
  }, [selectedValues]);

  useEffect(() => {
    if (!isFirstRender) {
      onChange?.(selectedValuesState);
    }
    // selectedValuesState が変わるときのみ onChange を実行する
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValuesState]);

  return (
    <Dropdown
      verticalPosition={verticalPosition}
      horizontalPosition={horizontalPosition}
    >
      <div
        css={[
          dropdownCheckboxesTriggerStyle,
          isSelected && dropdownCheckboxesTriggerWithSelectedStyle,
        ]}
      >
        <span css={dropdownCheckboxesTriggerLabelStyle}>
          {isSelected
            ? `${label ? `${label}\uff1a` : ""}${selectedLabels.join(", ")}`
            : !placeholder
            ? label
            : placeholder}
        </span>
        <FontAwesomeIcon
          css={dropdownCheckboxesTriggerIconStyle}
          icon={faSortDown}
        />
      </div>
      <div css={dropdownCheckboxesContentStyle}>
        {hasSearchBox && (
          <div css={dropdownCheckboxesContentItemStyle}>
            <label css={dropdownCheckboxesSearchStyle}>
              <FontAwesomeIcon
                icon={faSearch}
                css={dropdownCheckboxesSearchIconStyle}
              />
              <Inputbox
                onChange={onInputboxChange}
                placeholder={searchBoxPlaceholder}
                value={query}
                isRHF
                css={dropdownCheckboxesSearchInputStyle}
              />
            </label>
          </div>
        )}
        <div css={dropdownCheckboxesContentItemStyle}>
          {filteredCheckboxes.length > 0 ? (
            <ul css={dropdownCheckboxesItemsStyle}>
              {filteredCheckboxes.map((checkbox) => (
                <li key={checkbox.value}>
                  <Checkbox
                    isRHF
                    {...checkbox}
                    css={dropdownCheckboxesItemContentStyle}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <p css={dropdownCheckboxesNoItemsStyle}>該当する項目がありません</p>
          )}
        </div>
        <div css={dropdownCheckboxesContentItemStyle}>
          <button
            css={dropdownCheckboxesDeselection}
            onClick={onAllClearClick}
            type="button"
          >
            選択解除
          </button>
        </div>
      </div>
    </Dropdown>
  );
};

const dropdownCheckboxesTriggerStyle = css`
  display: flex;
  align-items: center;
  padding: ${Spacing[4]} ${Spacing[8]};
  border: 1px solid ${Color.Neutral.Light.Primary};
  font-size: ${text.size[14]};
`;

const dropdownCheckboxesTriggerWithSelectedStyle = css`
  border-color: ${Color.Primary.Light};
  background-color: ${Color.Primary.Pale};
`;

const dropdownCheckboxesTriggerLabelStyle = css`
  flex-shrink: 1;
  min-width: 1px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 22px;
`;

const dropdownCheckboxesTriggerIconStyle = css`
  flex-shrink: 1;
  margin-left: ${Spacing[8]};
  font-size: ${text.size[10]};
  line-height: 1;
  transform: translateY(-2.5px);
`;

const dropdownCheckboxesContentStyle = css`
  border: 1px solid ${Color.Neutral.Light.Primary};
  border-radius: 2px;
  min-width: 200px;
  background-color: ${Color.White};
`;

const dropdownCheckboxesContentItemStyle = css`
  & + & {
    border-top: 1px solid ${Color.Neutral.Light.Primary};
  }
`;

const dropdownCheckboxesSearchStyle = css`
  display: flex;
  align-items: center;
  padding: ${Spacing[8]};
  cursor: text;
`;

const dropdownCheckboxesSearchIconStyle = css`
  flex-shrink: 1;
  font-size: ${text.size[14]};
  color: ${Color.Neutral.Light.Primary};
`;

const dropdownCheckboxesSearchInputStyle = css`
  margin-left: ${Spacing[8]};
  flex-shrink: 1;
  border: 0;
  padding: 0;
`;

const dropdownCheckboxesItemsStyle = css`
  padding: ${Spacing[4]} 0;
  max-height: 240px;
  overflow-y: auto;
`;

const dropdownCheckboxesNoItemsStyle = css`
  padding: ${Spacing[8]};
  font-size: ${text.size[12]};
  color: ${Color.Neutral.Base.Secondary};
`;

const dropdownCheckboxesItemContentStyle = css`
  padding: ${Spacing[8]};
  width: 100%;

  &:hover {
    background-color: ${Color.Neutral.Light.Secondary};
  }
`;

const dropdownCheckboxesDeselection = css`
  padding: ${Spacing[8]};
  width: 100%;
  color: ${Color.Neutral.Base.Secondary};
  font-size: ${text.size[14]};
  text-align: left;
`;

export default DropdownCheckboxes;
