import { BuildingDataSetFragment, GraphQLBuildingImageType } from "../graphql";

/**
 * 現在のUIでは1枚のビル画像しか表示できないので、最後に登録された1枚の画像を表示することにする。
 * @param buildingImages
 * @returns
 */
export const selectLatestBuildingImage = (
  buildingImages: {
    id: number;
    url: string;
    imageType: GraphQLBuildingImageType;
  }[],
  type?: GraphQLBuildingImageType,
) => {
  const images = [...buildingImages].filter((image) =>
    type ? type === image.imageType : true,
  );
  images.sort((a, b) => b.id - a.id);
  return images.length > 0 ? images[0].url : undefined;
};

/**
 * 現在のUIでは1枚のビル画像しか表示できないので、最後に登録された1枚の画像を表示することにする。
 * @param buildingImages
 * @returns
 */
export const selectGraphQLLatestBuildingImage = (
  buildingImages: BuildingDataSetFragment["buildingImages"],
  type?: GraphQLBuildingImageType,
) => {
  const images = [...buildingImages].filter((image) =>
    type ? type === image.imageType : true,
  );
  images.sort((a, b) => b.id - a.id);
  return images.length > 0 ? images[0].url : undefined;
};
