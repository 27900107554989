import { css } from "@emotion/react";
import React, { ComponentProps } from "react";
import { sticky } from "../../../../styles/mixin";
import { Color, Spacing, ZIndex } from "../../../../tokens";
import { Head as TableHead } from "../../../uiParts/dataDisplay/Table";
import BuildingItem from "./BuildingItem";

type BuildingType = ComponentProps<typeof BuildingItem>["building"];

type Props = {
  buildings: BuildingType[];
  onClickEdit: (buildingId: number) => void;
};

// TODO: empty style
const BuildingList: React.FC<Props> = ({ buildings, onClickEdit }) => {
  return (
    <div>
      <div css={headStyle}>
        <TableHead
          headItems={[
            {
              content: "ビル",
              textAlign: "Left",
            },
            {
              content: "管理フロア",
              textAlign: "Left",
            },
            {
              content: "PM担当者",
              minWidth: "180px",
              maxWidth: "180px",
              textAlign: "Left",
            },
            {
              content: "LM担当者",
              minWidth: "180px",
              maxWidth: "180px",
              textAlign: "Left",
            },
            {
              content: "",
              minWidth: "48px",
              maxWidth: "48px",
            },
          ]}
          isNoBorder
        />
      </div>
      <ul css={listStyle}>
        {buildings.map((building, idx) => (
          <li key={building.id}>
            <BuildingItem
              building={building}
              color={idx % 2 === 0 ? Color.White : Color.Neutral.Pale.Secondary}
              onClickEdit={() => onClickEdit(building.id)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

const headStyle = css`
  ${sticky("0", "auto", "auto", "auto")}
  padding: ${Spacing[8]} ${Spacing[8]} 0 ${Spacing[8]};
  background: ${Color.White};
  z-index: ${ZIndex.TableHead};
`;

const listStyle = css`
  padding: 0 ${Spacing[8]} ${Spacing[8]} ${Spacing[8]};
`;

export default BuildingList;
