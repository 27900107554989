import { useMemo } from "react";
import { buildingMatchByName } from "../../../../../../../utils/BuildingFilter";

type BaseBuildingType = {
  name: string;
  oldNames: { name: string }[];
  lessorBranch?: { id: number } | null;
  pmLessorUser?: { id: number } | null;
  lmLessorUser?: { id: number } | null;
};

export const useDisplayBuildings = <BuildingType extends BaseBuildingType>(
  buildings: BuildingType[],
  propertySearchText: string,
  selectedBranches: number[],
  selectedAssignees: number[],
): { displayBuildings: BuildingType[] } => {
  const displayBuildings = useMemo(
    () =>
      buildings
        .filter((building) => buildingMatchByName(building, propertySearchText))
        .filter((building) => {
          return selectedBranches.length > 0
            ? selectedBranches.includes(building.lessorBranch?.id ?? -1)
            : true;
        })
        .filter((building) => {
          if (selectedAssignees.length <= 0) {
            return true;
          }

          return (
            (building.pmLessorUser?.id &&
              selectedAssignees.includes(building.pmLessorUser?.id)) ||
            (building.lmLessorUser?.id &&
              selectedAssignees.includes(building.lmLessorUser?.id))
          );
        }),
    [buildings, propertySearchText, selectedBranches, selectedAssignees],
  );

  return {
    displayBuildings,
  };
};
