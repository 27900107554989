import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Color, Spacing, LineHeight } from "../../../../tokens";

type Props = {
  title?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  icon?: IconDefinition;
  iconOnClick?: () => void;
};

const TopBar: React.FC<Props> = ({
  title,
  children,
  className,
  icon,
  iconOnClick,
}) => {
  return (
    <div css={wrapperStyle} className={className}>
      {title && (
        <div css={iconAndTitleStyle}>
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              css={iconStyle(iconOnClick !== undefined)}
              onClick={iconOnClick}
            />
          )}
          <h1 css={titleStyle}>{title}</h1>
        </div>
      )}
      {children}
    </div>
  );
};

const wrapperStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Spacing[8]};
  background: ${Color.White};
  border-bottom: solid 1px ${Color.Neutral.Light.Primary};
  @media print {
    border-bottom: 0;
  }
`;

const iconAndTitleStyle = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${Spacing[8]};
`;

const iconStyle = (hasOnClick: boolean) => css`
  font-size: ${text.size[16]};
  color: ${Color.Neutral.Base.Secondary};
  ${hasOnClick ? "cursor: pointer;" : ""}
`;

const titleStyle = css`
  font-size: ${text.size[16]};
  font-weight: bold;
  line-height: ${LineHeight[200]};
  color: ${Color.Neutral.Base.Primary};
`;

export default TopBar;
