import { escapeRegExp } from "lodash";

// ビル名で絞り込みを行う
// 旧ビル名も検索に含める
export const buildingMatchByName = (
  building: { name: string; oldNames: { name: string }[] },
  propertySearchText: string,
): boolean => {
  const escapedQuery = escapeRegExp(propertySearchText.toLocaleLowerCase());
  const currentNameMatches = building.name
    .toLocaleLowerCase()
    .match(escapedQuery);
  const oldNameMatches = building.oldNames.some((oldName) =>
    oldName.name.toLocaleLowerCase().match(escapedQuery),
  );
  return Boolean(currentNameMatches || oldNameMatches);
};
