import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import React from "react";
import { NO_USER_IMAGE_PATH } from "../../../../config";
import { flexAlignItemsCenter, textCut, grid } from "../../../../styles/mixin";
import { Spacing, LineHeight, Color, RadiusSize } from "../../../../tokens";

type Props = {
  name?: string;
  imgSrc?: string;
  othersNum?: number;
  isUnassigned?: boolean;
  className?: string;
};

const Avatar: React.FC<Props> = ({
  imgSrc,
  name,
  othersNum,
  isUnassigned = false,
  className,
}) => {
  return !isUnassigned ? (
    <div css={userStyle} className={className}>
      <img
        css={imgStyle}
        src={imgSrc || NO_USER_IMAGE_PATH}
        alt="プロフィール画像"
      />
      {name && (
        <p css={mainTextStyle}>
          <span css={nameStyle}>{name}</span>
          {!!othersNum && <span css={othersNumStyle}>他{othersNum}人</span>}
        </p>
      )}
    </div>
  ) : (
    <div css={userStyle}>
      <div css={userImgNoAssignStyle} />
      <p css={userNameNoAssignStyle}>未割り当て</p>
    </div>
  );
};

const userStyle = css`
  ${flexAlignItemsCenter}
  width: 100%;
`;

const imgStyle = css`
  width: 28px;
  height: 28px;
  border-radius: ${RadiusSize[999]};
  border: 1px solid ${Color.Opacity[20]};
  object-fit: cover;
`;

const mainTextStyle = css`
  ${grid("1fr auto", 0, "none", "normal")}
  align-items: end;
  font-size: ${text.size[14]};
  line-height: ${LineHeight[140]};
  color: ${Color.Neutral.Base};
  margin-left: ${Spacing[8]};
`;

const nameStyle = css`
  ${textCut}
  width: 100%;
`;

const othersNumStyle = css`
  font-size: ${text.size[12]};
  color: ${Color.Neutral.Base.Secondary};
  margin-left: ${Spacing[2]};
`;

const userImgNoAssignStyle = css`
  width: 28px;
  height: 28px;
  border: 1px dashed ${Color.Opacity[20]};
  border-radius: ${RadiusSize[999]};
  background: ${Color.Neutral.Pale.Primary};
`;

const userNameNoAssignStyle = css`
  font-size: ${text.size[14]};
  color: ${Color.Neutral.Base.Secondary};
  margin-left: ${Spacing[8]};
`;

export default Avatar;
