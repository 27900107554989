import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { faSearch, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback } from "react";
import { flexCenter, placeholderColor } from "../../../../styles/mixin";
import { Color, Spacing, RadiusSize } from "../../../../tokens";

export type Props = {
  id?: string;
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  className?: string;
  testId?: string;
};

const Searchbox: React.FC<Props> = ({
  id,
  name,
  value,
  placeholder = "キーワードを入力してください",
  onChange,
  className,
  testId,
}) => {
  const onChangeInput = useCallback(
    (value: string) => {
      if (typeof onChange === "function") {
        onChange(value);
      }
    },
    [onChange],
  );

  return (
    <div css={wrapperStyle} className={className}>
      <label htmlFor={id} css={labelStyle}>
        <div css={iconStyle(Color.Neutral.Light.Primary)}>
          <FontAwesomeIcon icon={faSearch} />
        </div>
        <input
          type="search"
          id={id}
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChangeInput(e.currentTarget.value)}
          css={inputStyle}
          data-testid={testId}
        />
        {value && (
          <a
            onClick={() => onChangeInput("")}
            css={iconStyle(Color.Neutral.Base.Secondary)}
          >
            <FontAwesomeIcon icon={faTimesCircle} />
          </a>
        )}
      </label>
    </div>
  );
};

const wrapperStyle = css`
  position: relative;
`;

const labelStyle = css`
  display: grid;
  grid-template-columns: 16px 1fr 16px;
  grid-gap: ${Spacing[4]};
  align-items: center;
  padding: ${Spacing[4]} ${Spacing[8]};
  background: ${Color.White};
  border: 1px solid ${Color.Neutral.Light.Primary};
  border-radius: ${RadiusSize[2]};
`;

const inputStyle = css`
  background: transparent;
  border: none;
  padding: 0;
  font-size: ${text.size[14]};
  line-height: 22px;
  ${placeholderColor(Color.Neutral.Light.Primary)}
`;

const iconStyle = (color: React.CSSProperties["color"]) => css`
  ${flexCenter}
  width: 16px;
  height: 16px;
  font-size: ${text.size[14]};
  color: ${color};
`;

export default Searchbox;
