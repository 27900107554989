import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import TopBar from "../../../uiParts/layout/TopBar";
import { ActionButton } from "../../../uiParts/trigger/ActionButton";

type Props = {
  onClickCreateBuilding: () => void;
};

const PropertyHead: React.FC<Props> = ({ onClickCreateBuilding }) => {
  return (
    <TopBar title="管理物件">
      <ActionButton
        kind="Secondary"
        icon={faPlusCircle}
        label="物件追加"
        onClick={onClickCreateBuilding}
        testId="property_head_create_building_button"
      />
    </TopBar>
  );
};

export default PropertyHead;
