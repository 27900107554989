import { useCallback, useMemo, useState } from "react";
import { ApiLessorUser } from "../../../../../../../repository";

type LessorUser = {
  id: number;
  name: string;
};

export const useAssigneesFilter = (
  lessorUserId: number,
  lessorUsers: LessorUser[],
) => {
  const [selectedAssignees, setSelectedAssignees] = useState<
    ApiLessorUser["id"][]
  >([]);

  const onSelectedAssigneesChange = useCallback((branches: number[]) => {
    setSelectedAssignees(branches);
  }, []);

  const assigneesItems = useMemo(
    () =>
      lessorUsers
        .sort((user) => {
          return user.id === lessorUserId ? -1 : 0;
        })
        ?.map((user) => ({
          label: `${user.name}${user.id === lessorUserId ? "（自分）" : ""}`,
          value: user.id,
        })) ?? [],
    [lessorUsers, lessorUserId],
  );

  return {
    selectedAssignees,
    onSelectedAssigneesChange,
    assigneesItems,
  };
};
