import Head from "next/head";
import { useQuery } from "urql";
import Main from "../../../components/organisms/common/Main";
import Header from "../../../components/organisms/lessor/Header";
import PropertyManagement from "../../../components/organisms/lessor/property/PropertyManagement";
import LoadingHandler from "../../../components/uiParts/feedback/LoadingHandler";
import { BuildingsForPropertyPageDocument } from "../../../graphql";
import useAuthLessorUser from "../../../hooks/authLessorUser";
import { productName } from "../../../utils/BrandConstants";

const PropertiesPage = () => {
  const { lessorUser, component } = useAuthLessorUser();
  const [{ data: buildingsData, error: buildingsError }] = useQuery({
    query: BuildingsForPropertyPageDocument,
  });

  if (!lessorUser) {
    return component;
  }
  if (buildingsError) {
    return <div>ビルの読み込みに失敗しました</div>;
  }
  return (
    <>
      <Head>
        <title>管理物件 | {productName}</title>
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="description" content="管理物件情報を作成・編集できます。" />
        <meta property="og:title" content={`管理物件 | ${productName}`} />
        <meta
          property="og:description"
          content="管理物件情報を作成・編集できます。"
        />
      </Head>
      <Header currentTab="properties" lessorUser={lessorUser} />
      <Main>
        <LoadingHandler isLoading={!buildingsData}>
          <PropertyManagement
            buildings={buildingsData?.buildings ?? []}
            lessorId={lessorUser.lessorId}
            lessorUserId={lessorUser.id}
            lessorBranchId={lessorUser.lessorBranchId}
            lessorBranches={buildingsData?.lessorBranches ?? []}
            lessorUsers={buildingsData?.lessor.lessorUsers ?? []}
          />
        </LoadingHandler>
      </Main>
    </>
  );
};

export default PropertiesPage;
